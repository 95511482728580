import { Component, Prop, Mixins, Watch } from '@zento-lib/components';
import { CmsBlock } from '@zento/modules/molecule/CmsBlock/CmsBlock';
import { decodePhpHtmlString, extractContent } from '@zento-lib/util/html';
import { InnerHTML } from '@zento/modules/atom/InnerHTML';

import { FormField } from './FormField';
import { ValidatorChecked } from './types';
import { ValidationMechanisms } from './validation/mechanisms';
import { ValidationMessage } from './Message';
import type { ICheckbox } from './Checkbox.d';
import { InputPropagationEventType } from './Input';
import style from './style.scss';

@Component({})
export class Checkbox extends Mixins<FormField<boolean, InputPropagationEventType, ICheckbox, any>>(FormField) {
  /**
   * Check if the validator checked exists in the context of current field instance
   */
  protected static validatorCheckedExtraction(instance: Checkbox): ValidatorChecked | null {
    if ('checked' in instance && instance.checked !== null) {
      const value = instance.checked;

      return { value };
    }

    return null;
  }

  /**
   * Input checked property
   */
  @Prop({ type: [Boolean, null], default: null })
  checked?: boolean | null;

  /**
   * Input type property
   */
  @Prop({ type: String, default: 'checkbox' })
  type?: 'checkbox' | 'radio';

  /**
   * Label property
   */
  @Prop({ type: [Object, String], required: false, default: '' })
  label?: any;

  /**
   * Second Label property
   */
  @Prop({ type: [Number, String], required: false, default: '' })
  secondLabel?: any;

  /**
   * Determines custom label
   */
  @Prop({ type: String, required: false, default: '' })
  customLabel?: string;

  /**
   * Determines custom label content
   */
  @Prop({ type: Boolean, required: false, default: false })
  showMoreContent?: boolean;

  /**
   * Determines if checkbox is disabled
   */
  @Prop({ type: Boolean, required: false, default: false })
  disabled?: boolean;

  /**
   * Determines data id, needed for qa tests
   */
  @Prop({ type: String, default: '' })
  dataTestId?: string;

  @Watch('value')
  onContentChange(newVal, oldVal) {
    if (newVal !== oldVal) {
      const input = this.$el?.querySelector('input');

      if (input) {
        input.checked = newVal;
      }
    }
  }

  /**
   * Record of validation rule names to validation parameters extraction functions
   */
  protected validationRules = {
    [FormField.RequiredRuleName]: FormField.validatorRequiredExtraction,
    checked: Checkbox.validatorCheckedExtraction,
  };

  protected validationMechanisms = {
    [FormField.RequiredRuleName]: ValidationMechanisms.boolean.required,
    checked: ValidationMechanisms.boolean.checked,
  };

  /**
   * Input unique id seed
   */
  private seed = Math.floor(Math.random() * 1000);

  public render() {
    const props = {
      type: this.type,
      checked: this.$data.value,
      onChange: this.handleChange,
      name: this.name,
      id: this.computedId,
      'data-testId': this.dataTestId,
      disabled: this.disabled,
    };

    return (
      <ValidationMessage type='boolean' state={this.validationState} validationParams={this.rules}>
        <div
          class={{
            [style.radio]: this.type === 'radio',
            [style.radioActive]: this.$data.value && this.type === 'radio',
            [style.checkbox]: this.type === 'checkbox',
            [style.checkboxActive]: this.$data.value && this.type === 'checkbox',
            [style.disabled]: this.disabled,
          }}>
          <input {...props} />
          {/* TODO: Temporary added label here to ease the styling. Will be moved to a separate component. */}
          <label for={this.computedId}>
            {this.customLabel ? (
              <span>
                <CmsBlock identifier={this.customLabel} showMoreContent={this.showMoreContent} />
              </span>
            ) : (
              <span>
                {/* Check if label contains HTML */}
                {this.label.match(/<\/?[a-z][\s\S]*>/i) ? (
                  <InnerHTML contents={decodePhpHtmlString(this.label)} />
                ) : (
                  this.getTranslation(this.label)
                )}

                {this.secondLabel ? <strong>{this.secondLabel}</strong> : null}
              </span>
            )}
          </label>
        </div>
      </ValidationMessage>
    );
  }

  /**
   * Handle change events
   */
  private handleChange(ev: Event) {
    // Cache the value in local component state
    const target = ev.target;
    if (target && 'value' in target) {
      this.$data.value = (target as any).checked;
    }

    this.propagateValue(ev.type as InputPropagationEventType);

    // Propagate event to other interested parties
    this.propagateEvent(ev);
  }

  /**
   * Computed input id getter
   * Ensures input's id uniqueness
   */
  private get computedId() {
    // Is HTML
    if (this.name.match(/<\/?[a-z][\s\S]*>/i)) {
      return `${extractContent(this.name)}_${this.seed}`;
    } else {
      return `${this.name}_${this.seed}`;
    }
  }
}
